import "./index.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PatternLateral from "../../components/patternLateral/PatternLateral"
import JoinUsForm from "../../components/forms/JoinUsForm"

export default function IndexPage() {
  const { t } = useTranslation()

  return (
    <Layout territory={"care"}>
      <Seo title={t("Join us")} />
      <h1 className="sectionTitle">
        <Trans i18nKey="CareTitle">
          Are you ready
          <br />
          to <span className="care">join us</span>?
        </Trans>
      </h1>

      <p className="mainText contactBody" id="formTop">
        <Trans>
          From València to the world we take care of our tribe. Please fill in
          the next form if you are interested in joining us. It should take less
          than 10 minutes.
        </Trans>
      </p>

      <PatternLateral territory="care" />
      <h2 className="sectionTitle">
        <Trans>Form</Trans>
      </h2>

      <JoinUsForm />

      <PatternLateral territory="care" />
      <h2 className="sectionTitle">
        <Trans>Contact</Trans>
      </h2>

      <p className="mainText contactBody center">
        <Trans i18nKey="StudioAddress">
          Avda. Dr Peset Aleixandre, 58 <br />
          46025 València, Spain
          <br />
          <br />
          96 088 38 79
        </Trans>
      </p>

      <PatternLateral territory="care" />
      <h2 className="sectionTitle">
        <Trans>Follow us</Trans>
      </h2>

      <div className="contactBody mainText followUs">
        <a
          href="https://www.instagram.com/tribuanimation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          href="https://twitter.com/tribuanimation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        <a
          href="https://www.youtube.com/channel/UCOVS7PWW3UYWyhpLfAQ1XLw/videos"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube
        </a>
        <a
          href="https://vimeo.com/latribuanimation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Vimeo
        </a>
        <a
          href="https://www.facebook.com/tribuanimation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          href="https://www.linkedin.com/company/la-tribu-animation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
