import "./RadioButtons1to5.css"
import React from "react"
import { Trans } from "react-i18next"

export default function RadioButtons1to5({ name, opt1, opt2 }) {
  return (
    <div className="radioButtons">
      <label htmlFor={name + "1"}>{opt1 || <Trans>Not me at all</Trans>}</label>

      <div>
        <input type="radio" name={name} id={name + "1"} value="1" required />
        <label htmlFor={name + "1"}>
          <p>1</p>
        </label>
      </div>

      <div>
        <input type="radio" name={name} id={name + "2"} value="2" />
        <label htmlFor={name + "2"}>
          <p>2</p>
        </label>
      </div>

      <div>
        <input type="radio" name={name} id={name + "3"} value="3" />
        <label htmlFor={name + "3"}>
          <p>3</p>
        </label>
      </div>

      <div>
        <input type="radio" name={name} id={name + "4"} value="4" />
        <label htmlFor={name + "4"}>
          <p>4</p>
        </label>
      </div>

      <div>
        <input type="radio" name={name} id={name + "5"} value="5" />
        <label htmlFor={name + "5"}>
          <p>5</p>
        </label>
      </div>

      <label htmlFor={name + "5"}>{opt2 || <Trans>Totally me</Trans>}</label>
    </div>
  )
}
