import "./JoinUsForm.css"

import React from "react"
import { Trans } from "react-i18next"
import { send } from "@emailjs/browser"
import { useTranslation } from "react-i18next"
import RadioButtons1to5 from "./RadioButtons1to5"
import { Link } from "gatsby-plugin-react-i18next"
import { CSSTransition } from "react-transition-group"
import { SwitchTransition } from "react-transition-group"

const FORM_ENDPOINT = ""

export default function JoinUsForm() {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [submitError, setSubmitError] = React.useState(false)
  const [formState, setFormState] = React.useState("")

  const [formData, setformData] = React.useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    countryOrigin: "",
    countryResidence: "",
    gender: "",
    birthdate: "",
    EUWorkPermit: "",
    mainSkill: "",
    mainSkillLvl: "",
    secondarySkill: "",
    secondarySkillLvl: "",
    prevCompany: "",
    prevCompanyRole: "",
    currentSituation: "",
    portfolioLink: "",
    reelLink: "",
    otherLinks: "",
    blenderSkill: "",
    metUs: "",
    leader: "",
    organizer: "",
    strategist: "",
    medic: "",
  })

  const handleSubmit = e => {
    e.preventDefault()

    setLoading(true)

    send("service_yu0m8jw", "template_ywlrq7h", formData, "-R9Ejgc_udHRsBYqQ")
      .then(response => {
        setFormState("submitSuccess")
        window.location.hash = "#formTop"
        console.log("Submit Success", response.status, response.text)
        console.log(response)
      })
      .catch(err => {
        setLoading(false)
        setSubmitError(true)
        console.log("Sumit failed", err.status, err.text)
      })
  }

  const handleChange = e => {
    setformData({ ...formData, [e.target.name]: e.target.value })
  }

  // React.useEffect(() => console.log(formData), [formData])

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={formState} timeout={150} classNames="fade">
        <div className="contactBody">
          {{
            submitSuccess: (
              <>
                <h3 className="sectionTitle">
                  <Trans>Hurray! We got your application</Trans>
                </h3>

                <p className="mainText contactBody">
                  <Trans>
                    Thank you for your submission. We have succesfully received
                    it and will get in touch with you very soon.
                  </Trans>
                  <br />
                  <br />
                  <Trans>Wanna look around some more?</Trans>{" "}
                  <Link to="/art">Explore our portfolio</Link>
                </p>
              </>
            ),
          }[formState] || (
            <form
              action={FORM_ENDPOINT}
              onSubmit={handleSubmit}
              onChange={handleChange}
              autoComplete="on"
              method="POST"
              target="_blank"
              className="contactForm"
            >
              <h3 className="basic">
                <Trans>Personal information</Trans>
              </h3>

              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  title={t("Your name")}
                  required
                />
                <label htmlFor="name">
                  <p>
                    <Trans>Name</Trans>
                  </p>
                </label>
              </div>

              <div>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  title={t("Your surname/s")}
                  required
                />
                <label htmlFor="surname">
                  <p>
                    <Trans>Surname</Trans>
                  </p>
                </label>
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  title={t("Your e-mail address")}
                  required
                  // placeholder={t("vegancannibal@island.es")}
                />
                <label htmlFor="email">
                  <p>
                    <Trans>E-mail</Trans>
                  </p>
                </label>
              </div>

              <div className="halfForm">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  title={t("Your phone number")}
                  required
                  // placeholder="+00 000 000 000"
                />
                <label htmlFor="phone">
                  <p>
                    <Trans>Phone number</Trans>
                  </p>
                </label>
              </div>

              <div className="halfForm">
                <select
                  id="countryOrigin"
                  name="countryOrigin"
                  title={t("Your country of origin")}
                  required
                  defaultValue={"Spain"}
                >
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
                <label htmlFor="countryOrigin">
                  <Trans>Where are you from?</Trans>
                </label>
              </div>

              <div className="halfForm ">
                <select
                  id="countryResidence"
                  name="countryResidence"
                  title={t("Your country of residence")}
                  required
                  defaultValue={"Spain"}
                >
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
                <label htmlFor="countryResidence">
                  <Trans>Where do you currently live?</Trans>
                </label>
              </div>

              <div className="halfForm">
                <select
                  id="gender"
                  name="gender"
                  title={t("Your gender")}
                  required
                  defaultValue={""}
                >
                  <option value="" disabled hidden>
                    {t("Choose Gender...")}
                  </option>
                  <option value="Female">{t("Female")}</option>
                  <option value="Male">{t("Male")}</option>
                  <option value="Other">{t("Other")}</option>
                </select>
                <label htmlFor="gender">
                  <Trans>Gender</Trans>
                </label>
              </div>

              <div className="halfForm">
                <input
                  type="date"
                  name="birthdate"
                  id="birthdate"
                  title={t("Your date of birth")}
                  required
                />
                <label htmlFor="birthdate">
                  <p>
                    <Trans>Date of birth</Trans>
                  </p>
                </label>
              </div>

              <h3 className="basic">
                <Trans>Job information</Trans>
              </h3>

              <div className="halfForm">
                <select
                  id="EUWorkPermit"
                  name="EUWorkPermit"
                  title={t("Do you have an European Work Permit?")}
                  required
                  defaultValue={""}
                >
                  <option value="" disabled hidden>
                    {t("Yes/No")}
                  </option>
                  <option value="Y">{t("Yes")}</option>
                  <option value="N">{t("No")}</option>
                </select>
                <label htmlFor="EUWorkPermit">
                  <Trans>European Work Permit</Trans>
                </label>
              </div>

              <div>
                <select
                  id="mainSkill"
                  name="mainSkill"
                  title={t("Tell us your main skill")}
                  required
                  defaultValue={""}
                >
                  <option value="" disabled hidden>
                    {t("Choose your main skill...")}
                  </option>
                  <option value="TD">{t("TD")}</option>
                  <option value="Generalist">{t("Generalist")}</option>
                  <option value="Pipeline Development">
                    {t("Pipeline Development")}
                  </option>
                  <option value="Software Development">
                    {t("Software Development")}
                  </option>
                  <option value="Production">{t("Production")}</option>
                  <option value="Storyboard">{t("Storyboard")}</option>
                  <option value="Storytelling">{t("Storytelling")}</option>
                  <option value="Scriptwriting">{t("Scriptwriting")}</option>
                  <option value="Animatic">{t("Animatic")}</option>
                  <option value="Previs">{t("Previs")}</option>
                  <option value="Layout">{t("Layout")}</option>
                  <option value="Concept">{t("Concept")}</option>
                  <option value="Chars Development">
                    {t("Chars Development")}
                  </option>
                  <option value="Chars Modeling">{t("Chars Modeling")}</option>
                  <option value={`Sets & Props`}>{t("Sets & Props")}</option>
                  <option value={`Sets & Props Modeling`}>
                    {t("Sets & Props Modeling")}
                  </option>
                  <option value="Colorscript">{t("Colorscript")}</option>
                  <option value="UV Mapping">{t("UV Mapping")}</option>
                  <option value="Rigging">{t("Rigging")}</option>
                  <option value="Textures">{t("Textures")}</option>
                  <option value="Shading">{t("Shading")}</option>
                  <option value="Lighting">{t("Lighting")}</option>
                  <option value="Compositing">{t("Compositing")}</option>
                  <option value="2D Animation">{t("2D Animation")}</option>
                  <option value="3D Animation">{t("3D Animation")}</option>
                  <option value="Fixing">{t("Fixing")}</option>
                  <option value="Set Dressing">{t("Set Dressing")}</option>
                  <option value="Scene Compo">{t("Scene Compo")}</option>
                  <option value="Hair Simulations">
                    {t("Hair Simulations")}
                  </option>
                  <option value="VFX">{t("VFX")}</option>
                  <option value="IT">{t("IT")}</option>
                  <option value="Helpdesk support">
                    {t("Helpdesk support")}
                  </option>
                  <option value="Administration">{t("Administration")}</option>
                  <option value="Marketing">{t("Marketing")}</option>
                  <option value="Communication">{t("Communication")}</option>
                  <option value="Sociology">{t("Sociology")}</option>
                  <option value="Behavior Analysis">
                    {t("Behavior Analysis")}
                  </option>
                </select>
                <label htmlFor="mainSkill">
                  <Trans>Main skill</Trans>
                </label>
              </div>

              <div className="halfForm pairedToPrev">
                <select
                  id="mainSkillLvl"
                  name="mainSkillLvl"
                  title={t("What is your main skill level?")}
                  required
                  defaultValue={""}
                >
                  <option value="" disabled hidden>
                    {t("Choose level...")}
                  </option>
                  <option value="Junior">{t("Junior")}</option>
                  <option value="Artist">{t("Artist")}</option>
                  <option value="Senior">{t("Senior")}</option>
                  <option value="Lead">{t("Lead")}</option>
                  <option value="Supervisor">{t("Supervisor")}</option>
                </select>
                <label htmlFor="mainSkillLvl">
                  <Trans>Main skill level</Trans>
                </label>
              </div>

              <div>
                <select
                  id="secondarySkill"
                  name="secondarySkill"
                  title={t("Tell us your secondary skill")}
                  className={!formData.secondarySkill ? "placeholder" : ""}
                  defaultValue={""}
                >
                  <option value="" disabled hidden>
                    {t("Choose your secondary skill...")}
                  </option>
                  <option value="">{t("None")}</option>
                  <option value="TD">{t("TD")}</option>
                  <option value="Generalist">{t("Generalist")}</option>
                  <option value="Pipeline Development">
                    {t("Pipeline Development")}
                  </option>
                  <option value="Software Development">
                    {t("Software Development")}
                  </option>
                  <option value="Production">{t("Production")}</option>
                  <option value="Storyboard">{t("Storyboard")}</option>
                  <option value="Storytelling">{t("Storytelling")}</option>
                  <option value="Scriptwriting">{t("Scriptwriting")}</option>
                  <option value="Animatic">{t("Animatic")}</option>
                  <option value="Previs">{t("Previs")}</option>
                  <option value="Layout">{t("Layout")}</option>
                  <option value="Concept">{t("Concept")}</option>
                  <option value="Chars Development">
                    {t("Chars Development")}
                  </option>
                  <option value="Chars Modeling">{t("Chars Modeling")}</option>
                  <option value={`Sets & Props`}>{t("Sets & Props")}</option>
                  <option value={`Sets & Props Modeling`}>
                    {t("Sets & Props Modeling")}
                  </option>
                  <option value="Colorscript">{t("Colorscript")}</option>
                  <option value="UV Mapping">{t("UV Mapping")}</option>
                  <option value="Rigging">{t("Rigging")}</option>
                  <option value="Textures">{t("Textures")}</option>
                  <option value="Shading">{t("Shading")}</option>
                  <option value="Lighting">{t("Lighting")}</option>
                  <option value="Compositing">{t("Compositing")}</option>
                  <option value="2D Animation">{t("2D Animation")}</option>
                  <option value="3D Animation">{t("3D Animation")}</option>
                  <option value="Fixing">{t("Fixing")}</option>
                  <option value="Set Dressing">{t("Set Dressing")}</option>
                  <option value="Scene Compo">{t("Scene Compo")}</option>
                  <option value="Hair Simulations">
                    {t("Hair Simulations")}
                  </option>
                  <option value="VFX">{t("VFX")}</option>
                  <option value="IT">{t("IT")}</option>
                  <option value="Helpdesk support">
                    {t("Helpdesk support")}
                  </option>
                  <option value="Administration">{t("Administration")}</option>
                  <option value="Marketing">{t("Marketing")}</option>
                  <option value="Communication">{t("Communication")}</option>
                  <option value="Sociology">{t("Sociology")}</option>
                  <option value="Behavior Analysis">
                    {t("Behavior Analysis")}
                  </option>
                </select>
                <label htmlFor="secondarySkill">
                  <Trans>Secondary skill (optional)</Trans>
                </label>
              </div>

              <div className="halfForm pairedToPrev">
                <select
                  id="secondarySkillLvl"
                  name="secondarySkillLvl"
                  title={t("What is your secondary skill level?")}
                  className={!formData.secondarySkillLvl ? "placeholder" : ""}
                  disabled={!formData.secondarySkill}
                  required={formData.secondarySkill}
                  defaultValue="N/A"
                >
                  <option value="N/A" hidden disabled>
                    {t("N/A")}
                  </option>
                  <option value="Junior">{t("Junior")}</option>
                  <option value="Artist">{t("Artist")}</option>
                  <option value="Senior">{t("Senior")}</option>
                  <option value="Lead">{t("Lead")}</option>
                  <option value="Supervisor">{t("Supervisor")}</option>
                </select>
                <label htmlFor="mainSkillLvl">
                  <Trans>Secondary skill level</Trans>
                </label>
              </div>

              <div>
                <input
                  type="text"
                  id="prevCompany"
                  name="prevCompany"
                  title={t("What's the last company you worked for?")}
                />
                <label htmlFor="prevCompany">
                  <p>
                    <Trans>Last company you worked for</Trans>
                  </p>
                </label>
              </div>

              <div className="halfForm pairedToPrev">
                <input
                  type="text"
                  id="prevCompanyRole"
                  name="prevCompanyRole"
                  title={t(
                    "What was your role in the last company you worked for?"
                  )}
                  disabled={!formData.prevCompany}
                  required={formData.prevCompany}
                />
                <label htmlFor="prevCompanyRole">
                  <p>
                    <Trans>Role (last company)</Trans>
                  </p>
                </label>
              </div>

              <div>
                <input
                  type="text"
                  id="currentSituation"
                  name="currentSituation"
                  title={t("Tell us briefly about your current situation?")}
                />
                <label htmlFor="currentSituation">
                  <p>
                    <Trans>Current situation</Trans>
                  </p>
                </label>
              </div>

              <div>
                <input
                  type="url"
                  id="portfolioLink"
                  name="portfolioLink"
                  title={t(
                    "Give us a URL address where we can find your portfolio"
                  )}
                  placeholder={t("https://dropbox.com/your-portfolio.pdf")}
                  required
                />
                <label htmlFor="portfolioLink">
                  <p>
                    <Trans>Link to your Portfolio</Trans>
                  </p>
                </label>
              </div>

              {/* TO-DO Porfolio upload (probably need a backend server for this) */}
              {/* <div>
            <p style={{ color: "red" }}>ADJUNTAR PORTFOLIO</p>{" "}
          </div> */}

              <div>
                <input
                  type="url"
                  id="reelLink"
                  name="reelLink"
                  title={t("Do you have any demo reel to showcase your work?")}
                  placeholder={t("https://youtube.com/your-demo-reel")}
                />
                <label htmlFor="reelLink">
                  <p>
                    <Trans>Reel link</Trans>
                  </p>
                </label>
              </div>

              <div>
                <input
                  type="url"
                  id="otherLinks"
                  name="otherLinks"
                  title={t("Other links")}
                  placeholder={t("https://other-links.com")}
                />
                <label htmlFor="otherLinks">
                  <p>
                    <Trans>Web/Other media references</Trans>
                  </p>
                </label>
              </div>

              <div>
                <p>
                  <Trans>Blender (use and handling) *</Trans>
                </p>

                <RadioButtons1to5
                  name="blenderSkill"
                  opt1={t("Not at all")}
                  opt2={t("Excellent")}
                />
              </div>

              <div>
                <select
                  id="metUs"
                  name="metUs"
                  title={t("Tell us where did you meet us or heard about us")}
                  defaultValue={""}
                >
                  <option value="" disabled hidden>
                    {t("Choose option...")}
                  </option>
                  <option value="RRSS - Instagram">{t("Instagram")}</option>
                  <option value="RRSS - LinkedIn">{t("LinkedIn")}</option>
                  <option value="RRSS - Fb">{t("Facebook")}</option>
                  <option value="RRSS - Twitter">{t("Twitter")}</option>
                  <option value="RRSS - Youtube">{t("Youtube")}</option>
                  <option value="Events - THU">{t("THU event")}</option>
                  <option value="Events - Other">{t("Other event")}</option>
                  <option value="Press">{t("Press")}</option>
                  <option value="Internet">{t("Around the internet")}</option>
                  <option value="Friend">{t("A friend told me")}</option>
                  <option value="Other">{t("Other")}</option>
                </select>
                <label htmlFor="metUs">
                  <Trans>How did you find out about us?</Trans>
                </label>
              </div>

              <h3 className="basic">
                <Trans>Final quest</Trans>
              </h3>

              <p>
                <Trans>
                  There has been a Draugr invasion. Yes. They are coming. La
                  Tribu will be vital to defeat them in an epic battle. What are
                  you gonna do?
                </Trans>
              </p>
              <p className="instructions">
                <Trans>
                  Rate the following statements from 1 to 5 according to how
                  much they represent you.
                </Trans>
              </p>

              <div>
                <p>
                  <Trans>
                    I'll lead the resistance. There's no time to waste!
                  </Trans>
                </p>
                <RadioButtons1to5 name="leader" />
              </div>

              <div>
                <p>
                  <Trans>
                    Let's put the weak in a safe place and the rest of us unite.
                    We can do this if we stay together!
                  </Trans>
                </p>
                <RadioButtons1to5 name="organizer" />
              </div>

              <div>
                <p>
                  <Trans>
                    I will be trying to gather as much information as possible
                    in order to design the best strategy against them.
                  </Trans>
                </p>
                <RadioButtons1to5 name="strategist" />
              </div>

              <div>
                <p>
                  <Trans>
                    You will find me taking care of those that are wounded and
                    sick.
                  </Trans>
                </p>
                <RadioButtons1to5 name="medic" />
              </div>

              <div className="formSubmit">
                {loading ? (
                  <h3>
                    <Trans>Enviando...</Trans>
                  </h3>
                ) : (
                  <>
                    <button type="submit">
                      <h3>
                        {submitError ? (
                          <Trans>Retry submit</Trans>
                        ) : (
                          <Trans>Submit</Trans>
                        )}
                      </h3>
                    </button>
                    {/* <button
                      onClick={() => {
                        setFormState("submitSuccess")
                        window.location.hash = "formTop"
                      }}
                    >
                      TEST SUBMIT
                    </button> */}
                  </>
                )}
              </div>
              {submitError && (
                <>
                  <p className="mainText contactBody">
                    <span className="art">
                      <Trans>Uh-oh... something went wrong</Trans>
                    </span>
                    <br />
                    <br />
                    <Trans>
                      There has been an error during your form submission. We're
                      very sorry. Plase try again in a few minutes.
                    </Trans>
                    <br />
                    <br />
                    <Trans>
                      If the problem persists, get in touch with us directly at
                      email@latribu.es
                    </Trans>
                  </p>
                </>
              )}
            </form>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}
